import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

//import images
import onlineImg1 from '../../assets/images/course/online/am2.jpeg';
import onlineImg2 from '../../assets/images/course/online/am.jpeg';
import onlineImg3 from '../../assets/images/course/online/am4.jpg';
import vision from '../../assets/images/course/online/vision.jpg';
import mission from '../../assets/images/course/online/mission.jpg';
import values from '../../assets/images/course/online/values1.jpg';


import Team from '../LandingTwo/Team';
import SectionTitle from '../../components/Shared/SectionTitle';

export default class AboutUs extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <Row className="align-items-center">
                <Col lg={6} xs={6} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                    <CardBody className="p-0">
                      <img src={onlineImg1} className="img-fluid" alt="" />
                      <div className="overlay-work bg-dark"></div>
                      <div className="content">
                        <Link
                          to="#"
                          className="title text-white d-block fw-bold"
                        >
                          Mr.Kashyap Modi
                        </Link>
                        <small className="text-light">Kashyap Diamond</small>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col lg={6} xs={6}>
                  <Row>
                    <Col lg={12} md={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                      <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                        <CardBody className="p-0">
                          <img src={onlineImg2} className="img-fluid" alt="" />
                          <div className="overlay-work bg-dark"></div>
                          <div className="content">
                            <Link
                              to="#"
                              className="title text-white d-block fw-bold"
                            >
                              Mr.Arun Modi
                            </Link>
                            <small className="text-light">
                              Kashyap Diamond
                            </small>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg={12} md={12} className="mt-4 pt-2">
                      <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                        <CardBody className="p-0">
                          <img src={onlineImg3} className="img-fluid" alt="" />
                          <div className="overlay-work bg-dark"></div>
                          <div className="content">
                            <Link
                              to="#"
                              className="title text-white d-block fw-bold"
                            >
                              Ms. Krunali Modi
                            </Link>
                            <small className="text-light">
                              Kashyap Diamond
                            </small>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col lg={7} md={6} className="mt-4 mt-lg-0 pt- pt-lg-0">
              <div className="ms-lg-4">
                <div className="section-title">
                  {/* <span className="badge bg-soft-primary rounded-pill fw-bold">
                    About us
                  </span> */}
                  <h4 className="title mb-4 mt-3">
                    Crafting Dreams in Every Facet: <br />
                    Your Journey to Sparkle Begins Here.
                  </h4>
                  <p className="text-muted para-desc">
                    Kashyap Diamond was established in the year 2015 by our
                    founder Mr. Arunbhai Modi, He is in this field since 1992.
                    We are committed to ensuring that we provide the best
                    quality Certified and Non-Certified Diamonds to our clients.
                    These years have been a big learning process for us as we
                    are constantly evolving to ensure that we always meet our
                    clients’ demands. For this, our skilled employees are very
                    focused on fulfilling the demands that our clients have put
                    forth. We have a global presence across Hong Kong, Dubai,
                    and New York to firmly establish ourselves in a growing
                    Diamond market.
                  </p>
                </div>

                {/* <div className="mt-4 pt-2">
                                    <Link to="//1.envato.market/landrickreactjs" rel='noopener noreferrer' target="_blank" className="btn btn-primary m-1">Read More <i className="uil uil-angle-right-b align-middle"></i></Link>
                                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-100">
          <SectionTitle title="Mission & Vision" desc="" />
        </Container>
        <Container style={{ marginTop: '30px' }}>
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={mission} className="img-fluid rounded-md shadow-lg" alt="" />
            </Col>
            <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title text-md-start text-center ms-lg-4">
                <h4 className="title mb-4">Mission</h4>
                <p className="text-muted mb-0 para-desc">
                  We are committed to delivering diamonds of exceptional quality
                  and brilliance. We strive to meticulously select and expertly
                  craft each diamond
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container style={{ marginTop: '30px' }}>
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={vision} className="img-fluid rounded-md shadow-lg" alt="" />
            </Col>
            <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title text-md-start text-center ms-lg-4">
                <h4 className="title mb-4">Vision</h4>
                <p className="text-muted mb-0 para-desc">
                  Our vision is to become the foremost global diamond supplier,
                  renowned for providing top-quality diamonds.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <Container style={{ marginTop: '30px' }}>
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img src={values} className="img-fluid rounded-md shadow-lg" alt="" />
            </Col>
            <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title text-md-start text-center ms-lg-4">
                <h4 className="title mb-4">Core Value</h4>
                <p className="text-muted mb-0 para-desc">
                  Transparency, Trust, and Tenacity. These core values form the
                  bedrock of our operations, empowering us to cultivate
                  unshakable relationships akin to the unbreakable nature of
                  diamonds. By upholding these values, we strive to create
                  long-term value for both our business and our customers,
                  fostering mutual success.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
