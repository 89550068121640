import React, { Component } from 'react';
import Client from '../../CorporateBusiness/Cilent';
import NavBar from '../../CorporateBusiness/NavBar';
import Footer5 from '../PageFooterLayouts/Footer5';
import Section from './Section';
import TeamPage from './TeamPage';
import { Container, Row, Col } from 'reactstrap';
import landing2 from '../../../assets/images/landing/origin-img.jpg';
import certificate from '../../../assets/images/landing/certificate.jpg';
import service1 from '../../../assets/images/landing/service-img1.png';
import service2 from '../../../assets/images/landing/service-img2.png';
import slide2 from '../../../assets/images/landing/slide2.png';


import { Link } from 'react-router-dom';
import ThemeSwitcher from '../../../components/Layout/ThemeSwitcher';
import SectionTitle from '../../../components/Shared/SectionTitle';

class CorporateTeam extends Component {
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <Section />
        <Container className="mt-100 mt-60">
          <SectionTitle
            title="PRECISION. PERFECTION. PRICELESS."
            desc="We offer a comprehensive range of polished diamonds to meet the needs of a diverse group of customers. Every diamond, whether bespoke or otherwise, is crafted with obsessive attention to detail. Our investment in innovative R & D combined with our collaborative partnering with Mining Companies puts us in the vanguard of bespoke products. Our portfolio of special cuts and mine-origin programmes enables us to deliver the differentiation that gives our customers a competitive edge in the market."
          />
        </Container>
        <section className="section">
          <Container>
            <Row className="align-items-center">
              <Col lg={5} md={6}>
                <img
                  src={landing2}
                  className="img-fluid rounded-md shadow-lg"
                  alt=""
                />
              </Col>

              <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title text-md-start text-center ms-lg-4">
                  <h4 className="title mb-4">GENERIC DIAMONDS</h4>
                  <p className="text-muted mb-0 para-desc">
                    Cut: Round Brilliant and Fancy shapes
                    <br />
                    Colour: D – M
                    <br />
                    Clarity: IF – I3
                    <br />
                    Size: 0.005 up to 5 ct
                  </p>

                  {/* <div className="d-flex align-items-center text-start mt-4 pt-2">
                    <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                      <i className="uil uil-capture"></i>
                    </div>
                    <div className="flex-1">
                      <Link to="#" className="text-dark h6">
                        Find Better Leads
                      </Link>
                    </div>
                  </div>

                  <div className="d-flex align-items-center text-start mt-4">
                    <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                      <i className="uil uil-file"></i>
                    </div>
                    <div className="flex-1">
                      <Link to="#" className="text-dark h6">
                        Set Instant Metting
                      </Link>
                    </div>
                  </div>

                  <div className="d-flex align-items-center text-start mt-4">
                    <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                      <i className="uil uil-credit-card-search"></i>
                    </div>
                    <div className="flex-1">
                      <Link to="#" className="text-dark h6">
                        Get Paid Seemlessly
                      </Link>
                    </div>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col lg={5} md={6}>
                <img
                  src={certificate}
                  className="img-fluid rounded-md shadow-lg"
                  alt=""
                />
              </Col>

              <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title text-md-start text-center ms-lg-4">
                  <h4 className="title mb-4">CERTIFIED DIAMONDS</h4>
                  <p className="text-muted mb-0 para-desc">
                    Cut: Round Brilliant and Fancy shapes
                    <br />
                    Colour: D – M; Clarity: IF – I1
                    <br />
                    Size: 0.25 up to 5 ct
                    <br />
                    Certificates: GIA, IGI, HRD, GSI, IIDGR, FM
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <section
            className="bg-half-170 d-table w-100 "
            style={{ background: `url(${slide2}) top`, marginTop: '30px' }}
          >
            <div className="bg-overlay"></div>
            <Container>
              <Row className="mt-5 justify-content-center">
                <Col lg={12} className="text-center">
                  <div className="pages-heading title-heading">
                    <h2 className="text-white title-dark">
                      {' '}
                      A COMMITMENT TO EXCELLENCE{' '}
                    </h2>
                    <h4 className="text-white"> OUR SERVICES </h4>
                    <p className="text-white-50 para-desc mb-0 mx-auto">
                      At the heart of our business strategy lays the sincerity
                      of always putting our customers first, and the passion to
                      create customer experiences that are personal, positive
                      and beyond expectations. Harnessing our vast knowledge of
                      the product and the market, we endeavour to fulfil our
                      customers’ every need by offering bespoke services and
                      value-add programmes.
                    </p>
                    <h4 className="text-white title-dark">
                      {' '}
                      A few of our service highlights are:{' '}
                    </h4>
                    <ul
                      className="text-white-50 para-desc mb-0 mx-auto"
                      style={{ textAlign: 'left' }}
                    >
                      <li>Just-in-time supply</li>
                      <li>
                        Consistently tailored assortments as per customers’
                        needs within a bagging and matching programme{' '}
                      </li>
                      <li>Calibrated sizes as per customers’ needs</li>
                      <li>Forward planning system</li>
                      <li>
                        Customised service through a widespread global network
                      </li>
                      <li>Certified diamonds by GIA, IGI, HRD and GSI</li>
                      <li>
                        Custom-made special cuts in accordance with customers’
                        requirement
                      </li>
                      <li>
                        {' '}
                        Mine-origin programmes – Code of Origin, De Beers
                        Forevermark and Canadamark
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col lg={5} md={6}>
              <div className="section-title text-md-start text-center ms-lg-4">
                  <h4 className="title mb-4">Custom Assortments</h4>
                </div>
                <img
                  src={service1}
                  className="img-fluid rounded-md shadow-lg"
                  alt=""
                />
              </Col>
              <Col lg={5} md={6}>
              <div className="section-title text-md-start text-center ms-lg-4">
                  <h4 className="title mb-4">Custom Sieve Sizes</h4>
                </div>
                <img
                  src={service2}
                  className="img-fluid rounded-md shadow-lg"
                  alt=""
                />
              </Col>
             
            </Row>
          </Container>
          {/* <TeamPage />
          <Client /> */}
        </section>
        <Footer5 />
      </React.Fragment>
    );
  }
}

export default CorporateTeam;
