import React, { Component } from 'react';
import NavBar from './NavBar';
import Footer5 from '../Pages/PageFooterLayouts/Footer5';
import Section from './Section';
import PageContactThree from '../Pages/Contact/PageContactThree';

class Contact extends Component {
  render() {
    return (
      <React.Fragment>
        <NavBar isDark={true}/>
        {/* <Section /> */}
        {/* <section className="section"> */}
          <PageContactThree />
        {/* </section> */}
        {/* <ExperienceTeam /> */}
        {/* <sectContaction className="section">
                    <Pricing />
                    <Client />
                    <Blog />
                </section> */}
        <Footer5 />
        {/* <ThemeSwitcher /> */}
      </React.Fragment>
    );
  }
}

export default Contact;
