import React, { Component } from 'react';
import Client from '../../CorporateBusiness/Cilent';
import NavBar from '../../CorporateBusiness/NavBar';
import Footer5 from '../PageFooterLayouts/Footer5';
import Section from './Section';
import TeamPage from './TeamPage';
import { Container, Row, Col } from 'reactstrap';
import image1 from '../../../assets/images/landing/1.png';
import image2 from '../../../assets/images/landing/2.png';
import image3 from '../../../assets/images/landing/3.png';
import image4 from '../../../assets/images/landing/4.png';
import image5 from '../../../assets/images/landing/5.png';
import image6 from '../../../assets/images/landing/6.png';
import image7 from '../../../assets/images/landing/7.png';
import image8 from '../../../assets/images/landing/8.png';
import image9 from '../../../assets/images/landing/9.png';

import { Link } from 'react-router-dom';
import ThemeSwitcher from '../../../components/Layout/ThemeSwitcher';
import SectionTitle from '../../../components/Shared/SectionTitle';

class CorporateTeam1 extends Component {
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <Section />
        {/* <Container className="mt-100 mt-60">
          <SectionTitle
            title="PRECISION. PERFECTION. PRICELESS."
            desc="We offer a comprehensive range of polished diamonds to meet the needs of a diverse group of customers. Every diamond, whether bespoke or otherwise, is crafted with obsessive attention to detail. Our investment in innovative R & D combined with our collaborative partnering with Mining Companies puts us in the vanguard of bespoke products. Our portfolio of special cuts and mine-origin programmes enables us to deliver the differentiation that gives our customers a competitive edge in the market."
          />
        </Container> */}
        <section className="section">
          <Container>
            <Row className="align-items-center">
              <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title text-md-start text-center ms-lg-4">
                  <h4 className="title mb-4">Blue Sapphire (Neelam)</h4>
                  <p className="text-muted mb-0 para-desc">
                    Blue sapphire is one of the prominent variety of Corundum
                    minerals and its lush blue hues is because of the presence
                    of chromium, vanadium, Magnesium, and Titanium. Its rarity,
                    combined with its exceptional hardness and brilliance, makes
                    it a prized choice for luxury jewelry. In terms of
                    astrological beliefs, Blue Sapphire is linked to promoting
                    mental clarity, spiritual growth, and prosperity. It
                    determines the value and the place of the gemstone on the
                    market. With certification, understanding the quality of the
                    gem becomes clearer and more reliable, giving an insight
                    into its history and treatments the gem has undergone.
                    symmetry, and facets that enhance its color and luster.
                  </p>
                </div>
              </Col>
              <Col lg={5} md={6}>
                <img
                  src={image1}
                  className="img-fluid rounded-md shadow-lg"
                  alt=""
                />
              </Col>
            </Row>
          </Container>
          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col lg={5} md={6}>
                <img
                  src={image2}
                  className="img-fluid rounded-md shadow-lg"
                  alt=""
                />
              </Col>

              <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title text-md-start text-center ms-lg-4">
                  <h4 className="title mb-4">Cat's Eye (Lehsunia)</h4>
                  <p className="text-muted mb-0 para-desc">
                    It refers to a unique gemstone that displays a captivating
                    optical phenomenon known as chatoyancy. The gemstone belongs
                    to the chrysoberyl mineral family and is characterized by a
                    distinct band of light that appears to glide across its
                    surface, resembling the slit-eye of a cat. This effect is
                    caused by the presence of parallel needle-like inclusions
                    within the stone. A high-quality Cat’s Eye gemstone exhibits
                    a strong and well-defined cat’s eye effect. The band of
                    light should be centered, bright, and easily visible under
                    proper lighting conditions
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title text-md-start text-center ms-lg-4">
                  <h4 className="title mb-4">Coral (Moonga)</h4>
                  <p className="text-muted mb-0 para-desc">
                    It is a unique organic gemstone formed from the skeletal
                    remains of marine polyps. It is primarily composed of
                    calcium carbonate and exhibits a range of colors, including
                    red, pink, white, and black. Coral has been used in jewelry
                    and decorative items for centuries and holds cultural
                    significance in many societies.
                  </p>
                </div>
              </Col>
              <Col lg={5} md={6}>
                <img
                  src={image3}
                  className="img-fluid rounded-md shadow-lg"
                  alt=""
                />
              </Col>
            </Row>
          </Container>
          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col lg={5} md={6}>
                <img
                  src={image4}
                  className="img-fluid rounded-md shadow-lg"
                  alt=""
                />
              </Col>

              <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title text-md-start text-center ms-lg-4">
                  <h4 className="title mb-4">Diamond (Heera)</h4>
                  <p className="text-muted mb-0 para-desc">
                    Diamond is the most coveted and highly valued gemstones in
                    the world. They are composed of carbon atoms arranged in a
                    crystal lattice structure, giving them exceptional hardness,
                    brilliance, and durability. Diamonds are graded based on the
                    “Four Cs” system, which includes cut, color, clarity, and
                    carat weight. Made available by global demand, natural
                    diamonds stone take centuries to form under high pressure
                    and temperature deep within the earth.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title text-md-start text-center ms-lg-4">
                  <h4 className="title mb-4">Emerald (Panna)</h4>
                  <p className="text-muted mb-0 para-desc">
                    It is a precious gemstone prized for its rich green color.
                    It is a variety of the mineral beryl, with the green hue
                    resulting from the presence of chromium and sometimes
                    vanadium. Emeralds have been treasured for thousands of
                    years and hold significant cultural and historical value.
                  </p>
                </div>
              </Col>
              <Col lg={5} md={6}>
                <img
                  src={image5}
                  className="img-fluid rounded-md shadow-lg"
                  alt=""
                />
              </Col>
            </Row>
          </Container>
          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col lg={5} md={6}>
                <img
                  src={image6}
                  className="img-fluid rounded-md shadow-lg"
                  alt=""
                />
              </Col>

              <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title text-md-start text-center ms-lg-4">
                  <h4 className="title mb-4">Hessonite (Gomed)</h4>
                  <p className="text-muted mb-0 para-desc">
                    This also known as “Cinnamon Stone” or “Gomed,” is a variety
                    of the mineral garnet. Hessonite is known for its
                    distinctive reddish-brown to orange-brown color and its
                    unique properties. From increasing its wearer’s happiness
                    and lifespan, these burnt sienna sparklers also offer an
                    influx of wealth and victory.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title text-md-start text-center ms-lg-4">
                  <h4 className="title mb-4">Pearl (Moti)</h4>
                  <p className="text-muted mb-0 para-desc">
                    This are unique organic gemstones formed within the soft
                    tissues of certain mollusks, primarily oysters and mussels.
                    They are renowned for their lustrous appearance and natural
                    beauty.
                  </p>
                </div>
              </Col>
              <Col lg={5} md={6}>
                <img
                  src={image7}
                  className="img-fluid rounded-md shadow-lg"
                  alt=""
                />
              </Col>
            </Row>
          </Container>
          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col lg={5} md={6}>
                <img
                  src={image8}
                  className="img-fluid rounded-md shadow-lg"
                  alt=""
                />
              </Col>

              <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title text-md-start text-center ms-lg-4">
                  <h4 className="title mb-4">Ruby (Manik)</h4>
                  <p className="text-muted mb-0 para-desc">
                    It is one of the most highly prized and sought-after
                    gemstones. It is a variety of the mineral corundum, with its
                    red color derived from the presence of chromium. Rubies have
                    been revered for centuries for their beauty, rarity, and
                    symbolic significance.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title text-md-start text-center ms-lg-4">
                  <h4 className="title mb-4">Yellow Sapphire (Pukhraj) </h4>
                  <p className="text-muted mb-0 para-desc">
                    Yellow Sapphire is a highly regarded gemstone known for its
                    vibrant yellow color and exceptional beauty. It is a variety
                    of the mineral corundum, with the yellow color attributed to
                    traces of iron within the crystal structure.
                  </p>
                </div>
              </Col>
              <Col lg={5} md={6}>
                <img
                  src={image9}
                  className="img-fluid rounded-md shadow-lg"
                  alt=""
                />
              </Col>
            </Row>
          </Container>
        </section>
        <Footer5 />
      </React.Fragment>
    );
  }
}

export default CorporateTeam1;
