import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

// import images
import landing from '../../assets/images/landing/Home2.png';
import landing1 from '../../assets/images/landing/Home3.png';

export default class Team extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={5} md={6}>
              <img
                src={landing}
                className="img-fluid rounded-md shadow-lg"
                alt=""
              />
            </Col>
            <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title text-md-start text-center ms-lg-4">
                <h4 className="title mb-4">Polished Diamonds</h4>
                <p className="text-muted mb-0 para-desc">
                  The difference between a rough diamond and a finished jewel is
                  the countless hours put in by a craftsman to bring in the
                  intense lustre and the spectacular finesse while delivering
                  the best quality through diamond cutting and polishing. Over
                  five decades, our qualified and skilled artisans have mastered
                  the art of diamond cutting; the knowledge and experience to
                  craft the best-polished masterpieces to reveal their full
                  potential and intrinsic beauty. You hold a piece of that
                  expert craftsmanship and secret knowledge in your hand when
                  you purchase our natural diamonds.
                </p>

                {/* <div className="d-flex align-items-center text-start mt-4 pt-2">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-capture"></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">Find Better Leads</Link>
                  </div>
                </div>

                <div className="d-flex align-items-center text-start mt-4">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-file"></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">Set Instant Metting</Link>
                  </div>
                </div>

                <div className="d-flex align-items-center text-start mt-4">
                  <div className="text-primary h4 mb-0 me-3 p-3 rounded-md shadow">
                    <i className="uil uil-credit-card-search"></i>
                  </div>
                  <div className="flex-1">
                    <Link to="#" className="text-dark h6">Get Paid Seemlessly</Link>
                  </div>
                </div> */}
              </div>
              <div
                className="post-meta d-flex justify-content-between mt-3"
                style={{ paddingLeft: '24px' }}
              >
                <Link
                  to="/products"
                  className="text-muted readmore"
                >
                  Read More <i className="uil uil-angle-right-b"></i>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mt-100 mt-60">
          <Row className="align-items-center">
            <Col lg={7} md={6} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title text-md-start text-center ms-lg-4">
                <h4 className="title mb-4"> Diamond Jewellery </h4>
                <p className="text-muted mb-0 para-desc">
                  The difference between a rough diamond and a finished jewel is
                  the countless hours put in by a craftsman to bring in the
                  intense lustre and the spectacular finesse while delivering
                  the best quality through diamond cutting and polishing. Over
                  five decades, our qualified and skilled artisans have mastered
                  the art of diamond cutting; the knowledge and experience to
                  craft the best-polished masterpieces to reveal their full
                  potential and intrinsic beauty. You hold a piece of that
                  expert craftsmanship and secret knowledge in your hand when
                  you purchase our natural diamonds.
                </p>
              </div>
              <div
                className="post-meta d-flex justify-content-between mt-3"
                style={{ paddingLeft: '24px' }}
              >
                <Link
                  to="https://smiya.in/"
                  className="text-muted readmore"
                  target="_blank"
                >
                  Read More <i className="uil uil-angle-right-b"></i>
                </Link>
              </div>
            </Col>
            <Col lg={5} md={6}>
              <img
                src={landing1}
                className="img-fluid rounded-md shadow-lg"
                alt=""
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
